<template>
 <div>
    <v-row  no-gutters>
          <va-update v-if="$store.state.required_update" :system="system"/>
          
            <!-- <v-col cols="6" class="pa-1 mt-2" v-for="(item, index) in RESULTS" :key="index">
                <va-two-card 
                  color="rescard"
                  icon="mdi-billiard"
                  :data="item"
                  sup=""
                />
              
            </v-col> -->
              <v-col cols="6" class="pa-1 mt-2"  v-for="(item, index) in $store.state.game_seq" :key="index">
                  <va-two-card 
                    color="rescard"
                    icon="mdi-billiard"
                    :data="GET_RESULT(item)"
                    sup=""
                  />
              </v-col>
       
      
 
        <v-col cols="6"  class="pa-2 mt-5">
            <v-btn small  block @click="$router.push('/results')"><v-icon  color="info" left>mdi-clipboard-list-outline</v-icon> RESULTS</v-btn>
        </v-col>
          <v-col cols="6"  class="pa-2 mt-5">
            <v-btn  small @click="how=true" block><v-icon  left color="info">mdi-account-question</v-icon> HOW TO PLAY</v-btn>
        </v-col>

    </v-row>
    <va-howto :show="how" @DialogEvent="hEvent"/>
  </div>
</template>
<script>
//import { API_MIXINS } from '@/mixins/api_mixins.js'
import {
  mapMutations
} from 'vuex'
  export default {
   // mixins: [API_MIXINS],
    data: () => ({
      
      watch: false,
      DRAWINTERVAL: null,
      next_draw:"",
      how:false,
      result: {},
        system: {},
    }),
    created() {
      this.PING()
        this.setData()
    },
     mounted(){
         this.setDrawer(false)
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.SYSTEM_UPDATE()
         
        }
        
    },
    beforeDestroy(){
       if(this.CHECKINTERVAL != null) { clearInterval(this.CHECKINTERVAL)}
       return true
    },
    computed:{
     
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      RESULTS() {
        return this.$store.state.results.length>0?this.$store.state.results:[]
      },
       RESULT2D() {
         return this.GET_RESULT("2D")
      },
      //  RESULT3D() {
      //     return this.GET_RESULT("3D")
      // },
      // SILAY() {
      //     return this.GET_RESULT("STL-SILAY")
      // },
      // CITY() {
      //     return this.GET_RESULT("STL-ILOILO-CITY")
      // },
      // PROVINCE() {
      //     return this.GET_RESULT("STL-ILOILO-PROVINCE")
      // },
      // PERYA() {
      //     return this.GET_RESULT("PERYA-ANTIQUE")
      // },
      item_count() {
        return this.$store.state.tickets.length.toString()
      },
      item_count_total() {
          var count = this.$store.state.tickets.reduce((res,item)=>res += this.$IsNum(item.amount), 0)
          return count.toString()
      },
      OPEN_DRAWS() {
       return this.$store.state.open_draws
      },
       OPEN_LIVE_DRAWS() {
       return this.$store.state.open_live_draws
      }
    
    },
    methods: {
      ...mapMutations(['setDrawer', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading', 'setOverlayLoadingText', 'setOpenMachine','setWatchDraw', 'setLiveDrawStatus']),
    
      hEvent(){
        this.how= false
      },
      GET_LABEL(game) {
          var data
          var results =  this.$store.state.results.length>0?this.$store.state.results:[]
          for(var i=0; i<results.length;i++) {
            if(results[i].game == game) {
              
                data =  this.GAMENAME(results[i].game) + " - "  +  results[i].time  + " DRAW"
                break
            } 
          }
       
          return data
      },
       GET_RESULT(game) {
          var data
          var results =  this.$store.state.results.length>0?this.$store.state.results:[]
          for(var i=0; i<results.length;i++) {
            if(results[i].game == game) {
               var nums = results[i].combination.split(" - ")
                results[i].nums = nums
                data =  results[i]
                break
            } 
          }
       
          return data
      },
      get_system_update() {
            //this.setRequiredUpdate(false)
            this.$http.get("system_update").then(response => {
              if(response.data.status) {
                 this.system = response.data.system
              }
               }).catch(e => {
                console.log(e.data)
            })
        },
        setData() {
          this.CHECK_BALANCE(this.user.id)
          var param = {}
          param.account = this.user.id
          param.method = "per_account"
          param.from_date =  this.$moment().format("YYYY-MM-DD")
          this.GET_TICKETS(param)
          this.GET_RESULTS(param)
        
          setTimeout(()=>{this.get_system_update()}, 800)
      }
    },
  }
</script>